// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/SearchBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/SearchBar.tsx");
  import.meta.hot.lastModified = "1733480438273.5366";
}
// REMIX HMR END

import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Form } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export function SearchBar() {
  _s();
  const {
    t
  } = useTranslation();
  const [isSearch, setIsSearch] = useState(false);
  let initialQuery = '';
  if (typeof window === 'undefined') {
    // running in a server environment
  } else {
    // running in a browser environment
    initialQuery = new URL(window.location.href).searchParams.get('q') ?? '';
  }
  return <Form method="get" action="/search" key={initialQuery} className='flex flex-row items-center space-x-2'>
      <input hidden={!isSearch} type="search" name="q" defaultValue={initialQuery} placeholder={t('common.search')} className="shadow-sm focus:ring-primary-500 focus:border-primary-500 w-64 sm:text-sm border-gray-300 rounded-md" />
      {!isSearch ? <MagnifyingGlassIcon className='w-6 h-6 cursor-pointer' onClick={() => setIsSearch(prev => !prev)} /> : <XMarkIcon className='w-5 h-5 cursor-pointer' onClick={() => setIsSearch(prev => !prev)} />}
    </Form>;
}
_s(SearchBar, "2nXs02we90dKvE503qVCqt64jwY=", false, function () {
  return [useTranslation];
});
_c = SearchBar;
var _c;
$RefreshReg$(_c, "SearchBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;