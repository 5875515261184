// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/accordion.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/accordion.tsx");
  import.meta.hot.lastModified = "1733480438281.5366";
}
// REMIX HMR END

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { cn } from '~/lib/utils';
const Accordion = AccordionPrimitive.Root;
const AccordionItem = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <AccordionPrimitive.Item ref={ref} className={cn('border-b', className)} {...props} />);
_c2 = AccordionItem;
AccordionItem.displayName = 'AccordionItem';
const AccordionTrigger = React.forwardRef(_c3 = ({
  className,
  children,
  ...props
}, ref) => <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger ref={ref} className={cn('flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline text-left [&[data-state=open]>svg]:rotate-180', className)} {...props}>
      {children}
      <ChevronDown className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>);
_c4 = AccordionTrigger;
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
const AccordionContent = React.forwardRef(_c5 = ({
  className,
  children,
  ...props
}, ref) => <AccordionPrimitive.Content ref={ref} className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down" {...props}>
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>);
_c6 = AccordionContent;
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
var _c, _c2, _c3, _c4, _c5, _c6;
$RefreshReg$(_c, "AccordionItem$React.forwardRef");
$RefreshReg$(_c2, "AccordionItem");
$RefreshReg$(_c3, "AccordionTrigger$React.forwardRef");
$RefreshReg$(_c4, "AccordionTrigger");
$RefreshReg$(_c5, "AccordionContent$React.forwardRef");
$RefreshReg$(_c6, "AccordionContent");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;